// url 마지막 파일명 가져오는 함수
export const getFileName = (data) => {
  const tempArr = data.split("/");
  return tempArr[tempArr.length - 1];
};

export const splitedBody = (body) => {
  const bodyArr = body.split("\r\n");
  return bodyArr;
};

export const shuffleArray = (array) => {
  for (let index = array.length - 1; index > 0; index--) {
    // 무작위 index 값을 만든다. (0 이상의 배열 길이 값)
    const randomPosition = Math.floor(Math.random() * (index + 1));

    // 임시로 원본 값을 저장하고, randomPosition을 사용해 배열 요소를 섞는다.
    const temporary = array[index];
    array[index] = array[randomPosition];
    array[randomPosition] = temporary;
  }
  return array;
};

export const calcProdPoint = (price) => {
  return Math.floor(price * (1 / 100));
};
