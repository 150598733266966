import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import GlobalArea from "../components/elements/GlobalArea";
import store from "../redux/store";
import "../public/assets/css/main.css";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
// Swiper Slider
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";
import StorageWrapper from "../components/ecommerce/storage-wrapper";
import getReferrer from "../common/util/getReferrer";
import getUserAgent from "../common/util/getUserAgent";
import sendUserAgent from "../api/sendUserAgent";
import checkSiteConfig from "../api/checkSiteConfig";
import Error from "../components/layout/Error";
import { useRouter } from "next/router";
import { getDate, getTimeStamp } from "../common/util/getDate";
import { insertVisit } from "../api/visits";
import { Html } from "next/document";
import Script from "next/script";
import * as gtag from "../lib/gtag";
import Loading from "../components/elements/Loading";
import Head from "next/head";
import { firebaseApp, getFCMToken } from "../lib/firebase";
import { getMessaging } from "firebase/messaging";
import axios from "axios";
import { io } from "socket.io-client";
import resources from "../api/constants/resources";
import { getAllUserInfo } from "../api/getUserInfo";
import { getCookie } from "../common/auth";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [userInfo, setUserInfo] = useState({});
  const [siteConfig, setSiteConfig] = useState({});
  const [socket, setSocket] = useState(null);

  let referrer;
  let userAgent = globalThis.navigator?.userAgent;
  let userDevice =
    userAgent?.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null || userAgent?.match(/LG|SAMSUNG|Samsung/) != null
      ? "mobile"
      : "web";

  const userAgentHandler = async () => {
    referrer = getReferrer();
    userAgent = getUserAgent();
    const body = {
      modes: "Connect",
      division: "store",
      referer: referrer,
      agent: userAgent,
    };

    const response = await sendUserAgent(body);

    if (response === "Success") return;
  };

  const slogCheck = async (url) => {
    referrer = getReferrer();
    if (!sessionStorage.getItem("slog")) {
      if (url.includes("products/P")) {
        sessionStorage.setItem(
          "slog",
          JSON.stringify({
            referer: referrer,
            pcode: url.split("/")[2],
          })
        );
      } else {
        sessionStorage.setItem(
          "slog",
          JSON.stringify({
            referer: referrer,
            pcode: "",
          })
        );
      }
    } else if (JSON.parse(sessionStorage.getItem("slog")).pcode === "[slug]") {
      sessionStorage.setItem(
        "slog",
        JSON.stringify({
          referer: referrer,
          pcode: url.split("/")[2],
        })
      );
    }
  };

  const checkSiteSetting = async () => {
    const body = {
      modes: "Config",
      division: "store",
      channel:
        router.query.channel ||
        (sessionStorage.getItem("channelName") === "바로고" ? "BAROGO" : ""),
    };
    const res = await checkSiteConfig(body);
    if (res?.Code === "0000") {
      if (res.Data.channel.token) {
        sessionStorage.setItem("channel", res.Data.channel.token);
        sessionStorage.setItem("channelName", res.Data.channel.company);
        sessionStorage.setItem(
          "channelInfo",
          `${res.Data.channel.company ? res.Data.channel.company + "+" : ""}${
            res.Data.channel.regIdYn
          }`
        );
      }
      sessionStorage.setItem(
        "logo",
        JSON.stringify({
          pc: res.Data.channel.logo,
          mobile: res.Data.channel.mobile_logo,
        })
      );
      setSiteConfig(res.Data);
      return;
    }
  };

  const checkRouter = (url) => {
    if (url.includes("[slug]")) return;
    if (url.includes("products/P")) {
      const today = getDate();
      const products = JSON.parse(sessionStorage.getItem(today) || "[]");
      const tempProducts = products.filter((el) => el === url.split("/")[2]);
      if (tempProducts.length) {
        return;
      }
      products.push(url.split("/")[2]);
      sessionStorage.setItem(today, JSON.stringify(products));
    }
    const date = getTimeStamp();
    const routes = JSON.parse(sessionStorage.getItem("route") || "[]");
    const routesDate = [];
    routes.forEach((el) => {
      for (const key in el) {
        if (key.slice(0, 11) === String(date).slice(0, 11))
          routesDate?.push(el);
      }
    });
    if (routesDate?.length) return;
    routes.push({ [date]: url });
    sessionStorage.setItem("route", JSON.stringify(routes));
  };

  // 유저정보 불러오는 함수
  const getUser = async () => {
    const res = await getAllUserInfo();

    if (res.Code === "0000") {
      setUserInfo(res?.Data);
    } else {
      setUserInfo({});
    }
  };

  useEffect(() => {
    if (!window.location.hostname.includes("192.168")) {
      getFCMToken();
    }
    userAgentHandler();
    checkRouter(router.asPath);
    getUser();

    const handleBeforeUnload = async (event) => {
      referrer = getReferrer();
      const today = getDate();
      const routes = sessionStorage.getItem("route");
      const products = sessionStorage.getItem(today);
      const body = {
        modes: "InsertSet",
        routes: routes || JSON.stringify([]),
        items: products || JSON.stringify([]),
        referrer: referrer,
      };

      const res = insertVisit(body);
      // 컴포넌트가 언마운트되기 전에 데이터 삭제
      sessionStorage.removeItem("route");
      sessionStorage.removeItem(today);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!router.isReady) return;

    if (!router.asPath) return;

    if (!window.location.hostname.includes("192.168")) {
      const newSocket = io(resources.SUPPORT);
      setSocket(newSocket);
      return () => {
        newSocket.disconnect();
      };
    }
  }, [router.isReady]);

  // socket
  useEffect(() => {
    if (socket) {
      // => 연결 성공
      socket?.on("connect", () => {
        // => 입장
        socket?.emit("joinRoom", "", "STORE", router.asPath);

        console.log("successfully connected.");
      });

      // 연결 해제
      socket?.on("disconnect", () => {
        console.log("disconnected.");
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      const isUserInfoValid = Object.keys(userInfo).length > 0 ? true : false;

      // 유저 현재 location
      const arr = [
        {
          url: router.asPath,
          ukey: isUserInfoValid ? userInfo?.Member?.ukey : "",
        },
      ];

      socket?.emit("storeAction", arr);
    }
  }, [router.asPath, userInfo]);

  useEffect(() => {
    if (!router.isReady) return;
    checkSiteSetting();
    if (getCookie("token")) {
      getUser();
    }

    const handleRouteChange = (url) => {
      gtag.pageview(url);
      gtag.gtmPageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeComplete", (url) => checkRouter(url));
    router.events.on("routeChangeComplete", (url) => slogCheck(url));
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeComplete", (url) => checkRouter(url));
      router.events.off("routeChangeComplete", (url) => slogCheck(url));
    };
  }, [router.isReady, router.events, router.query]);

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.ckeditor.com/ckeditor5/42.0.1/ckeditor5.css"
      />
      {/* ENP 공통스크립트 */}
      <Script
        async
        strategy="beforeInteractive"
        src="https://cdn.onetag.co.kr/0/tcs.js?eid=urgskck2lvmqurgskck2lv"
      />

      {/* Google tag (gtag.js)  */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
              debug_mode: true
            });
          `,
        }}
      />
      {/* <!-- Google Tag Manager --> */}
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtag.GA_TAGMANAGER_ID}');
        `,
        }}
      />

      {/* naver wcs_do START */}
      <Script
        id="naver-wcslog"
        strategy="beforeInteractive"
        defer=""
        src="//wcs.naver.net/wcslog.js"
      />
      {/* naver wcs_do END */}
      {/* <!-- digitalcamp 공통 전환 태그 BEGIN --> */}
      <Script
        id="digitalcamp"
        type="text/javascript"
        strategy="beforeInteractive"
        src="https://conv.digitalcamp.co.kr/tk.php?1255"
      />
      {/* <!-- digitalcamp 공통 전환 태그 END -->				 */}

      {/* ADN Tracker start */}
      <Script
        strategy="afterInteractive"
        src={`//fin.rainbownine.net/js/across_adn_2.0.1.js`}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.addEventListener('load', function() {
            var across_adn_contain = new fn_across_adn_contain();
            var across_adn_param = [];
            across_adn_param = {"ut":"Home","ui":"108274"};
            across_adn_contain.init(across_adn_param);
          })
        `,
        }}
      />
      {/* ADN Tracker end */}

      {/* BODY SCRIPT */}
      {/* <!-- WIDERPLANET  SCRIPT START 2023.5.8 --> */}
      <div id="wp_tg_cts" style={{ display: "none" }}></div>
      <Script
        id="wp_tg"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var UserAgent = navigator.userAgent;
          var widerplanet_device = (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) ? "mobile" : "web";
          var wptg_tagscript_vars = wptg_tagscript_vars || [];
          wptg_tagscript_vars.push(
          (function() {
            return {
              wp_hcuid:"",
              ti:"54644",
              ty:"Home",	
              device: widerplanet_device	
            };
          }));
        `,
        }}
      />
      <Script
        id="wp_astg"
        strategy="afterInteractive"
        async
        src="//cdn-aitg.widerplanet.com/js/wp_astg_4.0.js"
      />
      {/* <!-- // WIDERPLANET  SCRIPT END 2023.5.8 --> */}

      <Script
        id="service-worker"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: ` if (typeof window !== "undefined" &&"serviceWorker" in navigator)
        {window.addEventListener("load", () => {
          navigator.serviceWorker.register("/firebase-messaging-sw.js", {
            updateViaCache: "none",
          });
        })}
        `,
        }}
      />
      {!router.pathname.includes("viewer") &&
        (globalThis.innerWidth < 768 ? (
          <Script
            id="adn_closing"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
      var adn_mobile_panel_param = adn_mobile_panel_param || [];
      adn_mobile_panel_param.push([{
       ui:'108274',	
       ci:'1082740002',
       gi:'114308'
      }]);
    `,
            }}
          />
        ) : (
          <Script
            id="adn_closing"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          var adn_panel_param = adn_panel_param || [];
          adn_panel_param.push([{
             ui:'108274',	
             ci:'1082740002',
             gi:'114307'
            }]);
        `,
            }}
          />
        ))}

      {!router.pathname.includes("viewer") &&
        (globalThis.innerWidth < 768 ? (
          <Script
            id="adn_closing_ad_M"
            strategy="afterInteractive"
            async
            src="//fin.rainbownine.net/js/across_adn_closed_mobile_ad_1.0.4.js"
          />
        ) : (
          <Script
            id="adn_closing_ad"
            strategy="afterInteractive"
            async
            src="//fin.rainbownine.net/js/across_adn_closed_pc_ad_1.0.4.js"
          />
        ))}

      <link rel="stylesheet" href="/assets/css/scriptOverride.css" />
      {siteConfig.SiteOpen === "N" ? (
        <Error siteMsg={siteConfig.SiteMsg} />
      ) : (
        <Provider store={store}>
          <StorageWrapper />
          <GlobalArea />
          <Component
            {...pageProps}
            userDevice={userDevice}
            siteConfig={siteConfig}
          />
        </Provider>
      )}
      {/* 네이버 공통스크립트 WCS DO START */}
      {!router.pathname.includes("shop-cart") &&
        !router.pathname.includes("checkout/complete") &&
        !router.pathname.includes("customizing/complete") && (
          <Script
            id="naver-wcs-inflow"
            dangerouslySetInnerHTML={{
              __html: `
          if(!wcs_add) var wcs_add = {}; 
          wcs_add["wa"] = "4a45209655561c";
          if (!_nasa) var _nasa={};
          if (window.wcs) {
            wcs.inflow("caretstore.co.kr");
            wcs_do(_nasa);
          }
        `,
            }}
          />
        )}
      {/* 네이버 공통스크립트 WCS DO END */}
      <Script src="https://nsp.pay.naver.com/sdk/js/naverpay.min.js" />
    </>
  );
}

export default MyApp;
