import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SwiperCore, {
  Autoplay,
  Controller,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { getCookie, setCookie } from "../../common/auth";
import { getNextDate } from "../../common/util/getDate";

SwiperCore.use([Navigation, Autoplay, Pagination, Controller]);

const Wrapper = styled.div`
  position: ${(props) =>
    (props.$scroll === "bannerHidden" && "absolute") || "absolute"};
  top: ${(props) =>
    (props.$scroll === "scrollCheck" && "595px") ||
    (props.$scroll === "bannerHidden" && "0px") ||
    "665px"};
  left: calc(((100vw) / 2 - 570px) - 170px);
  max-width: 150px;
  height: 350%;
  z-index: 5;
  background-color: transparent;
  display: block;

  @media screen and (max-width: 1600px) {
    left: 5px;
  }
  @media screen and (max-width: 1200px) {
    top: 500px;
  }
  @media screen and (max-width: 991px) {
    top: 400px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 90px;
  height: auto;
  display: flex;
  align-items: center;
  & img {
    width: 150px !important;
  }
`;

const SwiperArrows = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 150px;
  & span {
    border-radius: 20%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background-color: #fff;
    margin-right: 5px;
    cursor: pointer;
    :last-of-type {
      margin-right: 0px;
    }
    z-index: 999;
    :hover {
      color: #3bb77e;
    }
  }
`;

const MobileBannerBg = styled.div`
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999999999;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const MobileBotBanner = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  padding: 10px 10px 5px;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: flex;
    .swiper-pagination-bullet {
      width: 10px !important;
      height: 10px !important;
    }
  }
`;

const BotImgWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  width: 100%;
`;

const BotBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  word-break: keep-all;
  & span {
    width: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    :hover {
      background-color: #ececec;
      color: #3bb77e;
    }
    :first-of-type {
      border-right: 1px solid #7e7e7e7e;
    }
  }
`;

const SideBanner = ({ banners }) => {
  const router = useRouter();
  const swiperRef = useRef();
  const mobileSwiperRef = useRef();
  const [openBanner, setOpenBanner] = useState(true);
  const [sideSwiper, setSideSwiper] = useState({});
  const [scroll, setScroll] = useState("");

  const onClickClose = () => {
    setOpenBanner(false);
  };

  const onClickTodayClose = () => {
    const date = getNextDate();
    setCookie(`IgB`, date, {
      expires: date,
    });
    onClickClose();
  };

  const swiperOption = {
    navigation: { prevEl: ".custom_prev", nextEl: ".custom_next" },
    autoplay: { delay: 3000, disableOnInteraction: false },
    loop: banners?.main_side?.length > 1 ? true : false,
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 100 && window.scrollY < 495;
      const bannderHidden = window.scrollY >= 495;

      if (scrollCheck) {
        return setScroll("scrollCheck");
      }
      if (bannderHidden) {
        return setScroll("bannerHidden");
      }
      setScroll("");
    });
  }, []);

  useEffect(() => {
    if (sideSwiper && sideSwiper.autoplay && banners?.main_side?.length > 1) {
      sideSwiper.autoplay.start();
      sideSwiper.autoplay.run();
      sideSwiper.update();
    }
    if (
      !getCookie(`IgB`) &&
      banners &&
      banners?.mobile_btm?.length > 0 &&
      openBanner &&
      mobileSwiperRef.autoplay
    ) {
      mobileSwiperRef.autoplay.start();
      mobileSwiperRef.autoplay.run();
      mobileSwiperRef.update();
    }
  });

  return (
    <>
      <Wrapper $scroll={scroll}>
        {banners && (
          <StickyWrapper>
            <Swiper
              ref={swiperRef}
              className="sideBanner"
              {...swiperOption}
              observeParents={true}
              observer={true}
              onSwiper={setSideSwiper}
              updateOnWindowResize={true}
            >
              {banners &&
                banners?.main_side?.length > 0 &&
                banners.main_side.map((el, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={el.link_url}
                      target={el.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <img src={el.P_FileUrl} alt={el.img_alt} />
                    </a>
                  </SwiperSlide>
                ))}
            </Swiper>
            <SwiperArrows
              className={banners?.main_side?.length == 1 && "d-none"}
            >
              <span className="custom_prev">
                <i className="fi-rs-angle-small-left" />
              </span>
              <span className="custom_next">
                <i className="fi-rs-angle-small-right" />
              </span>
            </SwiperArrows>
          </StickyWrapper>
        )}
      </Wrapper>
      {!getCookie(`IgB`) &&
        banners &&
        banners?.mobile_btm?.length > 0 &&
        openBanner && (
          <MobileBannerBg>
            <MobileBotBanner>
              <BotImgWrapper>
                <Swiper
                  className="mobileBotBanner "
                  autoplay={true}
                  pagination={true}
                >
                  {banners.mobile_btm.map((el, i) => (
                    <SwiperSlide key={i}>
                      <a
                        href={el.link_url}
                        target={el.new_win === "Y" ? "_blank" : "_self"}
                      >
                        <img src={el.M_FileUrl} alt={el.img_alt} />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </BotImgWrapper>
              <BotBtnWrapper>
                <span onClick={onClickTodayClose}>오늘 그만보기</span>
                <span onClick={onClickClose}>닫기</span>
              </BotBtnWrapper>
            </MobileBotBanner>
          </MobileBannerBg>
        )}
    </>
  );
};
export default SideBanner;
