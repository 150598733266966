import { useState } from "react";
import { priceComma } from "../../../common/splicePrice";
import * as U from "../user.styles";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { getCookie } from "../../../common/auth";
import { StoreOrderApi } from "../../../api/order";
import { Spinner } from "react-bootstrap";
import { insertBaskets } from "../../../api/baskets";
import { failAlert, successAddToCart } from "../../../common/alert";

const UserReOrder = (props) => {
  const { activeTab, fetchBasketsAction, setActievTab } = props;
  const router = useRouter();
  const [confirmItems, setConfirmItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [reOrderProducts, setReOrderProducts] = useState([]);
  const [reOrderLoading, setReOrderLoading] = useState(false);

  // 전체 선택
  const selectAllItems = (e) => {
    if (e.target.checked) {
      const tempArr = new Array(reOrderProducts.length).fill(true);
      setSelectedItem(tempArr);
      setConfirmItems(reOrderProducts);
    } else {
      const tempArr2 = new Array(reOrderProducts.length).fill(false);
      setSelectedItem(tempArr2);
      setConfirmItems([]);
    }
  };

  // 선택
  const selectItem = (e, el, i) => {
    const tempArr = [...selectedItem];
    tempArr[i] = e.target.checked;
    setSelectedItem(tempArr);
    if (e.target.checked) {
      const tempArr2 = [...confirmItems];
      tempArr2.push(el);
      setConfirmItems(tempArr2);
    } else {
      const tempArr2 = [...confirmItems];
      const index = tempArr2.findIndex((product) => product.pcode === el.pcode);
      tempArr2.splice(index, 1);
      setConfirmItems(tempArr2);
    }
  };

  // 개수 증가
  const increaseCnt = (item) => {
    const i = reOrderProducts.findIndex((el) => el.pcode === item.pcode);
    reOrderProducts[i].cnt = Number(reOrderProducts[i].cnt) + 1;
    setReOrderProducts([...reOrderProducts]);
  };

  // 개수 감소
  const decreaseCnt = (item) => {
    const i = reOrderProducts.findIndex((el) => el.pcode === item.pcode);
    if (reOrderProducts[i].cnt > 1) {
      reOrderProducts[i].cnt = Number(reOrderProducts[i].cnt) - 1;
      setReOrderProducts([...reOrderProducts]);
    }
  };

  // 전체 금액
  const price = () => {
    let price = 0;
    confirmItems?.forEach(
      (item) => (price += item.discount_price * Number(item.cnt))
    );

    return price;
  };

  // 재구매 컨펌
  const confirmReOrder = async () => {
    sessionStorage.setItem("repayOrder", JSON.stringify(confirmItems));
    setActievTab(undefined);
    router.push("/checkout?type=repay");
  };

  // 장바구니 담기
  const confirmCart = async () => {
    const body = {
      modes: "InsertSet",
      items: JSON.stringify(confirmItems),
    };
    const res = await insertBaskets(body);
    if (res.code === "0000") {
      fetchBasketsAction();
      successAddToCart();
    } else {
      failAlert(res.msg || "장바구니 담기 실패");
    }
  };

  // 재구매 리스트 불러오기
  const fetchReOrderProducts = async () => {
    setReOrderLoading(true);
    const body = {
      modes: "ReOrderProducts",
    };
    const res = await StoreOrderApi(body);
    if (res.Code === "0000") {
      if (selectedItem.length <= 0) {
        setSelectedItem(
          new Array(res.Data?.Data ? res.Data?.Data.length : 1).fill(false)
        );
      }
      setReOrderProducts(
        res.Data?.Data.map((el) => {
          return { ...el, cnt: 1 };
        })
      );
      setReOrderLoading(false);
    }
    if (res.Code === "9999") {
      failAlert(res.Data?.Msg || "재구매 리스트 불러오기 실패");
      setReOrderLoading(false);
    }
  };

  useEffect(() => {
    if (!router.isReady) return;
    if (getCookie("token")) {
      if (activeTab === "reorder") fetchReOrderProducts();
    }
  }, [router.isReady, activeTab]);

  return (
    <>
      {reOrderProducts.length > 0 ? (
        <U.InnerListWrapper>
          <div className="custome-checkbox">
            <input
              type="checkbox"
              className="form-check-input"
              id="checkReOrderAll"
              onChange={(e) => selectAllItems(e)}
              checked={!selectedItem.includes(false)}
            />
            <label
              className="form-check-label d-flex align-items-center text-dark"
              htmlFor="checkReOrderAll"
            >
              전체 선택
            </label>
          </div>
          {reOrderProducts.map((product, i) => (
            <div key={i} className="product_box">
              <div className="top">
                <div className="custome-checkbox">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkBox${i}`}
                    onChange={(e) => selectItem(e, product, i)}
                    checked={selectedItem[i] ? true : false}
                  />
                  <label
                    className="form-check-label d-flex"
                    htmlFor={`checkBox${i}`}
                  >
                    <h6 className="pname">{product.pname}</h6>
                  </label>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="main_image_wrapper"
                  onClick={() => router.push(`/products/${product.pcode}`)}
                >
                  <img
                    src={product.main_image}
                    alt={product.pname}
                    role="button"
                    className="main_image"
                  />
                  <div className="hover_cover">상세보기</div>
                </div>
                <div className="d-flex flex-column justify-content-between ms-2 py-1">
                  <div className="cnt_box">
                    <span className="icon">
                      <i
                        className="fi-rs-minus-small"
                        onClick={() => decreaseCnt(product)}
                      />
                    </span>
                    <span className="cnt">{Number(product.cnt)}</span>
                    <span className="icon">
                      <i
                        className="fi-rs-plus-small"
                        onClick={() => increaseCnt(product)}
                      />
                    </span>
                  </div>
                  <div className="price">
                    {priceComma(product.discount_price * Number(product.cnt))}원
                  </div>
                </div>
              </div>
            </div>
          ))}
        </U.InnerListWrapper>
      ) : (
        <U.InnerEmptyWrapper>
          {reOrderLoading && (
            <div className="bg-white position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" role="status" />
            </div>
          )}
          <span className="empty_icon">
            <i className="fi-rs-box-open" />
          </span>
          <p>이전 구매 내역이 없습니다.</p>
        </U.InnerEmptyWrapper>
      )}
      <U.ReOrderBtnWrapper>
        <div className="result">
          <div className="cnt">
            총 <strong>{confirmItems?.length}</strong>건
          </div>
          <div className="price">{priceComma(price())}원</div>
        </div>
        <button className="btn reorder" onClick={() => confirmReOrder()}>
          <i className="fi-rs-time-past me-2" />
          재구매
        </button>
        <button className="btn cart" onClick={() => confirmCart()}>
          <i className="fi-rs-shopping-cart me-2" />
          장바구니
        </button>
      </U.ReOrderBtnWrapper>
    </>
  );
};
export default UserReOrder;
