import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { getCookie, setCookie } from "../../common/auth";
import { changeNotiConfirmOption, logoutConfirm } from "../../common/confirm";
import {
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
} from "../../redux/action/confirm";
import { removeCookie } from "../../common/auth";
import {
  clearBasketsAction,
  deleteBasketsAction,
  fetchBasketsAction,
  initBaskets,
  insertBasketsAction,
} from "../../redux/action/baskets";
import { updateProductCategory } from "../../redux/action/productFiltersAction";
import { isLoaded, isLoading } from "../../redux/action/loading";
import { postMember } from "../../api/members";
import { failAlert, successAlert } from "../../common/alert";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import { fetchDummyList } from "../../redux/action/dummy";
import { PageRouter } from "../../common/pageRouter";
import UserFloating from "../floating/user";
import MobileSearch from "./mobileSearch/MobileSearch";
import {
  clearCart,
  decreaseCnt,
  deleteFromCart,
  increaseCnt,
} from "../../redux/action/cart";
import { fetchSiteInfo } from "../../redux/action/siteInfo";
import ProceedingMenuBadge from "../../common/badge/proceedingMenuBadge";
import StripeBanner from "./header/StripeBanner";
import FirstHeader from "./header/FirstHeader";
import SecondHeader from "./header/SecondHeader";
import { initUserInfo } from "../../redux/action/userInfo";
SwiperCore.use([Navigation, Pagination]);

const Header = ({
  logo,
  show,
  totalCartItems,
  toggleClick,
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
  userInfo,
  deleteBasketsAction,
  insertBasketsAction,
  fetchBasketsAction,
  clearBasketsAction,
  basketItems,
  basketsCount,
  initBaskets,
  deleteFromCart,
  updateProductCategory,
  productFilters,
  loading,
  userCoupons,
  isLoaded,
  isLoading,
  banners,
  fetchDummyList,
  dummyList,
  vp,
  cartItems,
  clearCart,
  decreaseCnt,
  increaseCnt,
  fetchSiteInfo,
  siteInfo,
  initUserInfo,
}) => {
  const router = useRouter();
  const [isToggled, setToggled] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [btnText, setBtnText] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [decideUrl, setDecideUrl] = useState("");
  const categoriRef = useRef(null);
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState("");
  const [awardIdx, setAwardIdx] = useState(0);
  const [menus, setMenus] = useState(null);
  const [searchToggled, setSearchToggled] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const scrollSearchBtnRef = useRef(null);

  const onClickSearch = () => {
    setSearchToggled(!searchToggled);
  };
  const isTokenValid = () => {
    const token = getCookie("token");
    const loginUrl = "login";
    const mypageUrl = "mypage/order";

    if (!token) return setDecideUrl(loginUrl);
    else return setDecideUrl(mypageUrl);
  };
  // 사용자 push 알림 허용 유무 정보
  const getPushStats = () => {
    if (userInfo?.Member?.pushYn === "Y") {
      setIsToggleActive(true);
    } else setIsToggleActive(false);
  };
  // 사용자 알림 on/off 설정 action handler
  const onClickToggleBtnActionHandler = async () => {
    setIsToggleActive(!isToggleActive);

    const body = {
      modes: "PushSet",
      select: !isToggleActive === true ? "Y" : "N",
    };

    const res = await postMember(body);

    if (res.Code === "0000") {
      closeConfirmModal();
      return successAlert(res.Data.Msg);
    } else {
      closeConfirmModal();
      return failAlert(res.Data.MSg);
    }
  };
  // 사용자 알림 on/off 설정
  const onClickToggleBtn = () => {
    modifyConfirmModal({
      ...changeNotiConfirmOption,
      onAction: () => {
        onClickToggleBtnActionHandler();
      },
    });
    return openConfirmModal();
  };
  // 토큰 유무에 따른 로그인 버튼 레이아웃 분기처리
  const showBtnText = () => {
    if (!getCookie("token")) {
      setIsUser(false);
      return setBtnText("로그인");
    } else if (getCookie("token")) {
      setIsUser(true);
      return setBtnText("로그아웃");
    }
  };

  const userName = useMemo(() => {
    if (userInfo?.Member?.name) {
      return userInfo?.Member?.name + "님";
    } else {
      return null;
    }
  }, [userInfo]);

  const userCompanyName = useMemo(() => {
    if (userInfo?.Company?.Data?.code) {
      return userInfo?.Company?.Data?.name;
    } else {
      return "";
    }
  }, [userInfo]);

  // 토큰 유무에 따른 로그인 및 로그아웃 액션 처리
  const authHandler = () => {
    if (!getCookie("token")) {
      return router.push("/login");
    } else {
      modifyConfirmModal(logoutConfirm(logoutConfirmHandler));
      return openConfirmModal();
    }
  };
  // 로그아웃 핸들러
  const logoutConfirmHandler = () => {
    removeCookie("token");
    initUserInfo();
    if (getCookie("username")) removeCookie("username");

    if (router.asPath === "/") {
      initBaskets();
      closeConfirmModal();
      router.reload();
    } else {
      initBaskets();
      closeConfirmModal();
      router.push("/");
    }
  };
  const hadleClickOutSide = (e) => {
    if (categoriRef.current) {
      if (!categoriRef.current?.contains(e.target)) {
        categoriRef.current.children[1].style.visibility = "hidden";
        categoriRef.current.children[1].style.zIndex = -1;
      } else {
        categoriRef.current.children[1].style.visibility = "visible";
        categoriRef.current.children[1].style.zIndex = 99;
      }
    }
  };
  // 메뉴 라우팅
  const buildPage = (pages) => {
    if (!pages) return;
    const temp = [...pages];

    const menus = temp.map((page, pageIdx) => {
      if (!page.sub) {
        if (page.url === "/group-purchase") {
          return (
            <li key={pageIdx}>
              <Link href={page.url}>
                <a
                  className={
                    router.asPath.split("/")[1] ===
                    page.url.split("?")[0].split("/")[1]
                      ? "active"
                      : ""
                  }
                >
                  <div className="position-relative">
                    <ProceedingMenuBadge siteInfo={siteInfo} />
                    {page.title}
                  </div>
                </a>
              </Link>
            </li>
          );
        } else {
          return (
            <li key={pageIdx}>
              <Link href={page.url}>
                <a
                  className={
                    router.asPath.split("/")[1] ===
                    page.url.split("?")[0].split("/")[1]
                      ? "active"
                      : ""
                  }
                >
                  {page.title}
                </a>
              </Link>
            </li>
          );
        }
      } else {
        return (
          <li key={pageIdx}>
            {page.url ? (
              <Link href={page.url}>
                <a
                  className={
                    page.sub
                      .map((el) => el.url.replaceAll("/", ""))
                      .includes(router.asPath.replaceAll("/", ""))
                      ? "active"
                      : ""
                  }
                >
                  {page.title}
                </a>
              </Link>
            ) : (
              <a
                role="button"
                className={
                  page.sub
                    .map((el) => el.url.replaceAll("/", ""))
                    .includes(router.asPath.replaceAll("/", ""))
                    ? "active"
                    : ""
                }
              >
                {page.title}
              </a>
            )}
            <ul style={{ minWidth: "200px" }} className="sub-menu">
              {buildPage(page.sub)}
            </ul>
          </li>
        );
      }
    });
    return menus;
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });

    document.addEventListener("mouseover", hadleClickOutSide);
    return () => {
      document.removeEventListener("mouseover", hadleClickOutSide);
    };
  }, []);

  useEffect(() => {
    if (banners?.main_award && banners.main_award.length > 0) {
      setTimeout(() => {
        if (awardIdx + 1 >= banners.main_award.length) {
          setAwardIdx(0);
          return;
        }
        setAwardIdx((prev) => prev + 1);
      }, 3000);
    }
  }, [banners, awardIdx]);

  useEffect(() => {
    getPushStats();
    isTokenValid();
    showBtnText();
  }, [userInfo]);

  useEffect(() => {
    if (!router.isReady) return;
    setMenus(buildPage(PageRouter));
    getCookie("token") && fetchBasketsAction();
    if (Object.keys(dummyList).length === 0) {
      fetchDummyList();
    }
    if (Object.keys(siteInfo).length === 0) {
      fetchSiteInfo(router);
    }
    if (typeof window !== "undefined" && "Notification" in window) {
      // 알림 허용 상태 변경 시 실행되는 함수
      const handlePermissionChange = () => {
        setNotificationPermission(Notification.permission);
      };

      // 알림 허용 상태 변경 이벤트를 감지하고 상태를 업데이트합니다.
      Notification.requestPermission().then(handlePermissionChange);
      window.addEventListener("change", handlePermissionChange);

      // 컴포넌트 언마운트 시 이벤트 리스너를 제거합니다.
      return () => {
        window.removeEventListener("change", handlePermissionChange);
      };
    }
  }, [router.isReady, siteInfo]);

  return (
    <>
      <header className="header-area header-style-1 header-height-2">
        {/* 띠 배너 */}
        <StripeBanner banners={banners} />
        {/* 최상단 헤더 */}
        <FirstHeader
          logo={logo}
          banners={banners}
          awardIdx={awardIdx}
          scroll={scroll}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          scrollSearchBtnRef={scrollSearchBtnRef}
        />
        {/* 2번 헤더 */}
        <SecondHeader
          scroll={scroll}
          logo={logo}
          banners={banners}
          categoriRef={categoriRef}
          setToggled={setToggled}
          menus={menus}
          decideUrl={decideUrl}
          userName={userName}
          userCompanyName={userCompanyName}
          isUser={isUser}
          authHandler={authHandler}
          getCookie={getCookie}
          basketsCount={basketsCount}
          toggleClick={toggleClick}
          userInfo={userInfo}
          userCoupons={userCoupons}
          notificationPermission={notificationPermission}
          onClickToggleBtn={onClickToggleBtn}
          totalCartItems={totalCartItems}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          scrollSearchBtnRef={scrollSearchBtnRef}
          isToggleActive={isToggleActive}
        />
      </header>
      <MobileSearch
        searchToggled={searchToggled}
        onClickSearch={onClickSearch}
        dummyList={dummyList}
      />
      <UserFloating
        scroll={scroll}
        userInfo={userInfo}
        basketItems={basketItems}
        clearBasketsAction={clearBasketsAction}
        modifyConfirmModal={modifyConfirmModal}
        openConfirmModal={openConfirmModal}
        closeConfirmModal={closeConfirmModal}
        deleteBasketsAction={deleteBasketsAction}
        fetchBasketsAction={fetchBasketsAction}
        insertBasketsAction={insertBasketsAction}
        deleteFromCart={deleteFromCart}
        vp={vp}
        cartItems={cartItems}
        clearCart={clearCart}
        decreaseCnt={decreaseCnt}
        increaseCnt={increaseCnt}
        onClickSearch={onClickSearch}
        toggleClick={toggleClick}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  show: state.confirm.modal,
  totalCartItems: state.cart.length,
  cartItems: state.cart,
  basketItems: state.baskets.items,
  basketsCount: state.baskets.count,
  productFilters: state.productFilters,
  loading: state.loading.loading,
  banners: state.banners,
  dummyList: state.dummyList,
  vp: state.vp,
  siteInfo: state.siteInfo,
});

const mapDispatchToProps = {
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
  deleteBasketsAction,
  insertBasketsAction,
  fetchBasketsAction,
  clearBasketsAction,
  initBaskets,
  deleteFromCart,
  updateProductCategory,
  isLoaded,
  isLoading,
  fetchDummyList,
  clearCart,
  decreaseCnt,
  increaseCnt,
  fetchSiteInfo,
  initUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
